export const AppName = "Pdp";
export const ShouldUseClarityAutomate = true;
export const FeatureFlags = [
  "PDP_Offer_Title_Brand_Sku",
  "PDP_Compare_Tool",
  "Add_To_List_Badge_Expanded_PDP",
  "PDP_Show_Fitment_Feedback_Internals",
  "Ribbon_Text_Dollar_Vs_Percentage",
  "Engine_Fitment_Master_Flag",
  "FrontEnd_Engine_Fitment_UX",
  "FrontEnd_CustomerProject_Drawer_Enabled",
  "PromotionPreview_PDP_Frontend"
];
